import swal from 'sweetalert'
import {useContext} from 'react'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {processAnswers} from '../helpers/questionsHelper'
import {Navigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {Header, Message, Card, Container} from 'semantic-ui-react'

import {bindActionCreators} from 'redux'
import PassedMessage from '../components/messages/Passed'
import FailedMessage from '../components/messages/Failed'
import Question from '../components/exam/Question'
import Loading from '../components/messages/Loading'
import {
  canTakeExam,
  passFailedCert,
  passNotTakenCert,
  failFailedCert,
  failNotTakenCert
} from '../helpers/dynamoDBHelper'
import {getCertName} from '../helpers/certDataHelper'
import {getQuestions} from '../helpers/questionsHelper'
import {UserContext} from '../context/UserContext'
import {actionCreators} from '../state/index'
import CannotTakeExamPage from './CannotTakeExamPage'
import ErrorPage from './ErrorPage'
import {linkToBuyCerts} from '../helpers/certDataHelper'

const ExamPage = () => {
  const {currentUser} = useContext(UserContext)
  const {certId} = useParams()
  const navigate = useNavigate()
  const [questions, setQuestions] = useState(undefined)
  const [answers, setAnswers] = useState([])
  const [examState, setExamState] = useState(undefined)

  const state = useSelector((state) => state)
  const certName = getCertName(certId)
  const [userCanTakeExam] = useState(
    canTakeExam(state.passed, state.failed, state.notTaken, certName)
  )

  const dispatch = useDispatch()
  const AC = bindActionCreators(actionCreators, dispatch)

  useEffect(() => {
    if (questions) return
    const fetchQuestions = async () => {
      try {
        const examQuestions = await getQuestions(certId)

        setQuestions(examQuestions)
        setAnswers(Array(examQuestions.length))
      } catch (err) {
        console.log('Could not fetch the questions. Exam does not exist')
        setExamState('ErrorFetchingQuestions')
      }
    }
    fetchQuestions()
  }, [questions])

  if (currentUser === null) {
    console.log('Trying to take exam. User not signed in. Redirecting')
    return <Navigate to="/signin" />
  }

  const handleClick = (answer, idx) => {
    const newAnswers = [...answers]
    newAnswers[idx] = answer
    setAnswers(newAnswers)
  }

  const wasFailedExam = () => {
    for (const f of state.failed) {
      if (f.name.toLowerCase() === certName.toLowerCase()) return true
    }
    // It was not purchased
    return false
  }

  const handleAnswers = () => {
    const f = async () => {
      let isInvalidAttemp = false
      for (const answer of answers) {
        if (answer === undefined) {
          isInvalidAttemp = true
          break
        }
      }

      if (isInvalidAttemp) {
        swal('You need to answer all the questions before submitting')
        return
      }

      const hasPassed = processAnswers(answers)
      const wasFailed = wasFailedExam()

      setTimeout(() => {
        if (hasPassed) {
          if (wasFailed) {
            AC.passFailedCert(certName)
            passFailedCert(certName)
          } else {
            AC.passNotTakenCert(certName)
            passNotTakenCert(certName)
          }
          setExamState('Passed')
        } else {
          if (wasFailed) {
            AC.failFailedCert(certName)
            failFailedCert(certName)
          } else {
            AC.failCert(certName)
            failNotTakenCert(certName)
          }
          setExamState('Failed')
        }
      }, 1000)
    }
    f()
  }

  const goBack = () => {
    if (examState === 'Passed') navigate('/certificates')
    else if (examState === 'Failed') navigate('/exams')
    else navigate('/error')
  }

  if (userCanTakeExam === 'InvalidCertName') return <ErrorPage />

  if (userCanTakeExam === 'HasNotPurchased') {
    return (
      <CannotTakeExamPage
        message={`You have not purchased the ${certName} exam yet. Visit ${linkToBuyCerts} to buy it and gain access.`}
      />
    )
  }

  if (userCanTakeExam === 'AlreadyPassed') {
    return (
      <CannotTakeExamPage
        message={`You have already passed the ${certName} exam.`}
      />
    )
  }

  if (userCanTakeExam === 'WaitFailed') {
    return (
      <CannotTakeExamPage
        message={`You have failed the ${certName} exam and cannot retake it yet`}
      />
    )
  }

  if (questions === undefined) {
    return (
      <Loading
        header={'Just one second'}
        text={"We're fetching the questions"}
      />
    )
  }

  if (examState === 'ErrorFetchingQuestions') {
    return (
      <Message
        error
        size="massive"
        icon="exclamation triangle"
        header="There was a problem fetching your questions"
        content="Please, try again in a few moments"
      />
    )
  }

  if (userCanTakeExam === 'NotTaken' || userCanTakeExam === 'CanRetake') {
    return (
      <div>
        <div className="exam">
          <Header as="h1" textAlign="center" style={{marginBottom: '3rem'}}>
            {certName} exam
          </Header>
          <div style={{marginBottom: '3rem'}}>
            <Card.Group>
              {questions.map((q, i) => (
                <Question
                  question={q.question}
                  options={q.options}
                  value={answers[i]}
                  key={Math.random()}
                  idx={i}
                  handleClick={handleClick}
                />
              ))}
            </Card.Group>
          </div>
          {examState ? (
            examState === 'Passed' ? (
              <PassedMessage />
            ) : (
              <FailedMessage />
            )
          ) : null}
          <Container textAlign='center'>
            {examState ? (
              <button
                className="ui primary button"
                onClick={goBack}
                style={{fontSize: '1.5rem'}}
              >
                Go to {examState === 'Passed' ? 'certificates' : 'exams'}
              </button>
            ) : (
              <button
                className="ui primary button"
                onClick={handleAnswers}
                style={{fontSize: '1.5rem'}}
              >
                Submit
              </button>
            )}
          </Container>
        </div>
      </div>
    )
  } else {
    return <ErrorPage />
  }
}

export default ExamPage
